@use '../utils' as *;

/*----------------------------------------*/
/*  16. CONTACT CSS START
/*----------------------------------------*/
.tp-contact-area {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(23, 23, 23, .8);

    }
}

.tp-contact-form-title {
    font-size: 24px;
    margin-bottom: 40px;

    & i {
        margin-right: 8px;
    }
}

.tp-section-wrapper {
    z-index: 9;
    position: relative;
}

.tp-contact-info {
    @media #{$xs} {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-right: 0;
    }
}

.tp-contact-form {
    display: block;
    border-bottom: 5px solid var(--tp-common-black);
    z-index: 9;
    position: relative;
    top: 150px;

    @media #{$md} {
        top: 0;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 40px;
    }
    @media #{$xs} {
        top: 0;
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 40px;
    }

    & input {
        height: 60px;
        width: 100%;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 30px;
        background-color: var(--tp-grey-3);
        border: 0;

        @include tp-placeholder {
            color: var(--tp-text-body);
        }
    }

    & textarea {
        height: 215px;
        width: 100%;
        padding-top: 20px;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 30px;
        background-color: var(--tp-grey-3);
        resize: none;
        border: 0;

        @include tp-placeholder {
            color: var(--tp-text-body);
        }

        &:focus {
            outline: 0;
        }
    }
}

.tp-contact-form-2 {
    position: static;
}

.tp-ct-info__title {
    font-size: 24px;
    & i {
        margin-right: 15px;
    }
}

.p-color-change {
    & p {
        color: #C8C8C8;
    }
}

.ct-info{
    &__box{
        border: 1px solid #F1F1F1;
        & span {
            color: var(--tp-common-black);
        }
    }
}
.tp-ct-form {
    border: 1px solid #F1F1F1;
    @media #{$xs} {
       padding-left: 15px;
       padding-right:15px;
    }
    & input {
        width: 47%;
        float: left;
        margin-right: 30px;
        height: 60px;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 30px;
        background-color: var(--tp-grey-3);
        border: 0;
        @media #{$xl,$lg,$md} {
            width: 100%;
        }
        @media #{$xs} {
            width: 100%;
        }
        @include tp-placeholder {
            color: var(--tp-text-body);
        }
    }

    & select { 
        width: 97%;
        // float: left;
        // margin-right: 30px;
        height: 60px;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 30px;
        background-color: var(--tp-grey-3);
        border: 0;
        @media #{$xl,$lg,$md} {
            width: 100%;
        }
        @media #{$xs} {
            width: 100%;
        }
        @include tp-placeholder {
            color: var(--tp-text-body);
        }
    }
    & textarea {
        height: 209px;
        width: 97%;
        padding-top: 20px;
        margin-bottom: 20px;
        padding-left: 20px;
        padding-right: 30px;
        background-color: var(--tp-grey-3);
        resize: none;
        border: 0;
        @media #{$xl,$lg,$md} {
            width: 100%;
        }
        @media #{$xs} {
            width: 100%;
        }
        @include tp-placeholder {
            color: var(--tp-text-body);
        }

        &:focus {
            outline: 0;
        }
    }

}
.tp-ct-map{
    height:787px;
    margin-top: -140px;
    position: relative;
    z-index: -1;
    @media #{$xs} {
        height: 480px;
    }
    & iframe{
        width: 100%;
        height: 100%;
    }
}