@use '../utils' as *;

/*----------------------------------------*/
/*  05. SERVICE CSS START
/*----------------------------------------*/

.tp-service {
    height: 100%;
    @media #{$xs} {
        padding-left: 15px;
        padding-right: 15px;
    }

    &__icon {
        font-size: 52px;
        color: var(--tp-common-black);
    }

    &__title {
        font-size: 20px;

        & a {
            background-image: linear-gradient(#000, #000), linear-gradient(#000, #000);
            display: inline;
            background-size: 0% 1px, 0 1px;
            background-position: 100% 100%, 0 100%;
            background-repeat: no-repeat;
            transition: background-size 0.4s linear;

            &:hover {
                color: #000;
                background-size: 0 1px, 100% 1px;
            }
        }
    }

    p {
        font-size: 15px;
        margin-bottom:  25px;
        padding: 0 10px;
        height: 100px;
    }
}

//  service page style here 

.tp-service-ara{
    @media #{$xs} {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
.tp-ab-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
}

.tp-ab-text {
    padding-right: 200px;

    @media #{$lg,$md} {
        padding-left: 60px;
        padding-right: 30px;
    }

    @media #{$xs} {
        padding-left: 15px;
        padding-right: 15px;
    }

}

.serive-vd-play {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 128px;
    height: 128px;
    left: 0;
    right: 0;
    margin: 0 auto;

    & button {
        display: block;
        text-align: center;
        line-height: 128px;
        cursor: pointer;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: var(--tp-common-white);
        font-size: 25px;
        color: var(--tp-common-black);
        border: 1px solid var(--tp-common-black);
    }
}

.tp-ct-info {
    @media #{$xs} {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.tp-ct-info-border {
    border-bottom: 1px solid #2F2F2F;
}

.tp-ct-info-icons {
    & a {
        width: 60px;
        height: 60px;
        display: inline-block;
        border: 1px solid rgba(255, 255, 255, .06);
        text-align: center;
        line-height: 60px;
        border-radius: 50%;
        margin-right: 15px;
        font-size: 20px;
        color: var(--tp-common-white);
        &:hover{
            color: var(--tp-common-black);
            background-color: var(--tp-common-white);
        }
        @media #{$lg} {
            margin-right: 5px;
            width: 50px;
            height: 50px;
            line-height: 50px;
            
        }
        @media #{$xs} {
            margin-right: 5px;
            width: 50px;
            height: 50px;
            line-height: 50px;
        }
    }
}

.tp-contact-form {
    @media #{$md,$xs} {
        margin-right: 0;
    }

    @media #{$xs} {
        padding-left: 0;
        padding-right: 0;

    }
}

.tp-sv-inner-img {
    img {
        border-radius: 10px;
    }
}


.tp_sv__video-bg {
    border-radius: 10px;
    background-size: cover;
}

.tp-sv {
    &__content {
        & p {
            padding-bottom: 20px;
        }
    }

    &__title {
        font-size: 43px;
        margin-bottom: 20px;
    }

    &__subtitle {
        font-size: 34px;
        margin-bottom: 20px;
        @media #{$xs} {
            font-size: 24px;
        }
    }

}

.sv-video {
    margin: 100px auto;
    width: 128px;
    height: 128px;
    background-color: var(--tp-common-white);
    font-size: 20px;
    text-align: center;
    display: block;
    border-radius: 50%;
    line-height: 128px;
    border: 1px solid var(--tp-common-black);
    color: #000;
}

.esa-service-card { 
    padding: 40px;
    height: 300px;
    background-color: white;
    box-shadow: 0px 0px 10px 0px #0000001a;
    width: 100%;
    transition : 0.6s;

    &:hover { 
        background-color: var(--tp-common-black);
        color: var(--tp-common-white);
        h4 { 
            color: var(--tp-common-white);
        }
        .description { 
            color: var(--tp-common-white,0.6);
        }

        .esa-step-number { 
            color: var(--tp-common-white);
        }

        .esa-service-card-icon { 
            img { 
                filter: brightness(0) invert(1);  
            }
        }
    }

    .description { 
        height: 300px;
        overflow: hidden;
    }
}


.divider { 
    height: 100%;
    width: 1px;
    background-color: var(--tp-grey-1);
}

.highlight-wrapper { 
    position :relative;
    flex-wrap: wrap;
}

.hyphen { 
    content: "";
display: inline-block;
width: 45px;
height: 2px;
background: #3d3d3d;
left: 0px;
top: 50%;
transform: translatey(-50%);
position: absolute;
margin-top: -2px;
}


.hyphenated { 
    &:before {
        content: "";
display: inline-block;
width: 30px;
height: 2px;
background: #3d3d3d;
left: 0px;
// top: 50%;
transform: translatey(-250%);
// position: absolute;
margin-right: 6px;
    }
    // padding-left: 60px;
    font-size: 18px;
    line-height: 1.2;
    color: var(--tp-common-gray);
    
}


.border-right { 
    border-right: 1px solid var(--tp-grey-2);
}

.border-bottom { 
    border-bottom: 1px solid var(--tp-grey-2);
}

.service-section-item-center { 
    height: 340px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    overflow-y: hidden;
    align-items: center;
    justify-content: center;
    
    // justify-content: flex-end;
}

.service-section-item { 
    height: 340px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    overflow-y: hidden;
    
    // justify-content: flex-end;
}

.ts-services { 
    background: url("../../img/service/ts-background.png") no-repeat center center;
}

.ts-services-white-container { 
    background-color: var(--tp-common-white);
    padding: 40px;
}

.ts-about-right { 
    margin-left: 24px;
}


.esa-about-left { 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}

.highlight-item { 
    position: relative;
    flex: 1;
    min-width: 250px;
    .background-image {
        height: 500px;
        // max-height: 400px;
        
        img { 
            object-fit: cover;
            height: 100%;
        }
    }
    .foreground-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        // align-items: center;
        padding: 20px 20px;
        color: var(--tp-common-white);
        overflow-y: hidden;
        background-color: rgba($color: #000000, $alpha: 0.6);
        transition: 0.6s;
        &:hover{
            background-color: rgba($color: #000000, $alpha: 0.8);
            padding-bottom: 40px;
            
        }

        h3 { 
            color: var(--tp-common-white);
        }
    
        .subtitle { 
            height: 40px;
        }
    }
}

.ib-about-img { 
    width: 100%;
    height: 400px;
    object-fit: cover;
}


.service-pill { 
    padding: 20px 20px;
    background-color: white;
    box-shadow: 0px 0px 10px 0px #0000001a;
    margin-bottom: 16px;

    &:hover {
        background-color: var(--tp-common-black);
        color: var(--tp-common-white);
    }
}